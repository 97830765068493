<script setup lang="ts">import { ref as _ref } from 'vue';

import { InjectionKeyDropdownContext } from '~/constants/symbols'

defineProps({
  "placement": null,
  "autoBoundaryMaxSize": { type: Boolean,  }
})

const dropdown = _ref<any>()
const colorMode = useColorMode()

const hide = () => dropdown.value.hide()
provide(InjectionKeyDropdownContext, {
  hide,
})

defineExpose({
  hide,
})
</script>

<template>
  <VDropdown v-bind="$attrs" ref="dropdown" :class="colorMode.value" :placement="placement || 'auto'" :auto-boundary-max-size="autoBoundaryMaxSize">
    <slot />
    <template #popper="scope">
      <slot name="popper" v-bind="scope" />
    </template>
  </VDropdown>
</template>
